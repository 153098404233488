"use client";

import { useRef } from "react";
import { useStore } from "react-redux";
import { RelayEnvironmentProvider } from "react-relay";

import { authLogout, checkAuth } from "@skydio/api_util/src/backends/cloud_api/auth/asyncThunks";

import makeEnvironment, {
  ensureSubscriptionClientConnected,
  makeSubscriptionClient,
} from "relay/jwtEnvironment";
import { relayContext } from "relay/relayContext";
import { isAuthenticated } from "state/auth";
import { relayActions } from "state/relay/slice";

import type { ReactNode } from "react";
import type { SubscriptionClient } from "subscriptions-transport-ws";
import type { Environment } from "relay-runtime";
import type { AppStore } from "state/store";

export default function RelayProvider({ children }: { children: ReactNode }) {
  const store: AppStore = useStore();
  const environmentRef = useRef<Environment>();
  const subscriptionClientRef = useRef<SubscriptionClient>();

  if (!subscriptionClientRef.current) {
    subscriptionClientRef.current = makeSubscriptionClient(() => {
      if (isAuthenticated(store.getState())) {
        store.dispatch(checkAuth());
      }
    });
    if (typeof window !== "undefined") {
      // When the user brings the tab/browser back into visibility, check if the subscription websocket is
      // still alive.  Inspired by
      // https://levelup.gitconnected.com/high-resilience-with-web-sockets-315641e4f02c
      document.addEventListener("visibilitychange", () => {
        if (!document.hidden) {
          ensureSubscriptionClientConnected(subscriptionClientRef.current!);
        }
      });

      // If the browser comes back online (likely from a network disruption), check that the subscription
      // client is connected.  Inspired by
      // https://levelup.gitconnected.com/high-resilience-with-web-sockets-315641e4f02c
      window.addEventListener("online", () => {
        ensureSubscriptionClientConnected(subscriptionClientRef.current!);
      });
    }
  }

  if (!environmentRef.current) {
    environmentRef.current = makeEnvironment({
      subscriptionClient: subscriptionClientRef.current,
      logQuery: query => store.dispatch(relayActions.logQuery(query)),
      onJwtExpired: () => {
        // When expired, explicitly invoke the redux logout logic; we want to go back to the
        // login page.
        store.dispatch(authLogout());
      },
    });
  }

  return (
    <relayContext.Provider
      value={{
        subscriptionClient: subscriptionClientRef.current,
      }}
    >
      <RelayEnvironmentProvider environment={environmentRef.current}>
        {children}
      </RelayEnvironmentProvider>
    </relayContext.Provider>
  );
}
